import React from "react"
import HeaderMobile from "../components/Header/HeaderMobile"
import Welcome from "../components/welcomepage/Welcome/Welcome"
import Introduction from "../components/welcomepage/Introduction/Introduction"
import Helmet from "react-helmet"
import Education from "../components/welcomepage/Education/Education"
import Footer from "../components/Footer/Footer"

const IndexMobil = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://jakabjanosieszter.hu" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Bemutatkozás - Jakab-Jánosi Eszter, érdi pszichológus</title>
        <meta
          name="description"
          content="Jakab-Jánosi Eszter vagyok érdi pszichológus. Főként gyermekpszichológiával, nevelési tanácsadással, családterápiával és párterápiával foglalkozom"
        />
      </Helmet>
      <Welcome>
        <HeaderMobile />
      </Welcome>
      <Introduction />
      <Education />
      <Footer />
    </>
  )
}

export default IndexMobil
